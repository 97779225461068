import React, { useEffect, useState } from 'react';
import { fetchNewsData } from './api'; 
import './News.css';

function News() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const newsData = await fetchNewsData(); // Use the fetchNewsData function
      setData(newsData);
    };

    fetchData();
  }, []);

  return (
    <div className='news-section'>
      <div className="news-section-content">
        <h1>News and Updates</h1>
        <div className="newsCardWrapper">
          {data.map((item) => (
            <div key={item.id} className="newsCard">
              {item.documents && item.documents.length > 0 && (
                <div className="newsCardImageContainer">
                  {item.documents.map((document) => (
                    <img
                      key={document.id}
                      src={'https://api-gocontent.33digitec.xyz/api/document/detail?type=7&document='+document.psdocfnm}
                      className='newsCardImage'
                    />
                  ))}
                </div>
              )}
              <h2 className="newsCardTitle">{item.psconttl}</h2>
              <p className="newsCardContent">{item.psconcnt}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default News;
