import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './NumberCounter.css';
const NumberCounter = ({ endValue, duration, prefix, suffix, text, icon }) => {
  const [currentValue, setCurrentValue] = useState(0);
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      let animationInterval;
      const framesPerSecond = 90;
      const increment = (endValue / (duration / 1000)) / framesPerSecond;

      if (currentValue < endValue) {
        animationInterval = setInterval(() => {
          setCurrentValue((prevValue) => {
            const newValue = prevValue + increment;
            if (newValue >= endValue) {
              clearInterval(animationInterval);
              return endValue;
            }
            return newValue;
          });
        }, 1000 / framesPerSecond);
      } else {
        setCurrentValue(endValue);
      }

      return () => {
        clearInterval(animationInterval);
      };
    }
  }, [inView, currentValue, endValue, duration]);

  return (
    <div className="container" ref={ref}>
        {icon && <div className="icon">{icon}</div>}
        <span className="number">
            {prefix}
            {Math.round(currentValue).toLocaleString()}
            {suffix}
        </span>
        {text && <span className="text">{text}</span>}
    </div>
  );
};

export default NumberCounter;
