import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter } from 'react-icons/fa';
import logo from '../images/GOOD ASIA CONSTRUCTION (White hori).png';

import './Footer.css';

const Footer = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    subject: 'General Enquiry', // Default to 'General'
    message: '',
  });

  function sendMail(subject, message, name) {
    const email = 'goodasiamarketing@gmail.com';
    const emailSubject = encodeURIComponent(subject + ' - ' + name);
    const emailBody = `Dear GOOD ASIA CONSTRUCTION,\n\n${message}\n\nBest Regards,\n${name}`;
    const mailtoLink = `mailto:${email}?subject=${emailSubject}&body=${encodeURIComponent(emailBody)}`;
    window.open(mailtoLink, '_blank');
  }
  
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

     // Form validation checks
    if (!formData.firstName || !formData.lastName || !formData.email || !formData.phone || !formData.message) {
      alert('Please fill out all required fields.');
      return;
    }

    if (!isValidEmail(formData.email)) {
      alert('Please enter a valid email address.');
      return;
    }

    // Determine the thank you message based on the selected subject
    let message = 'Thank you for submitting.';
    if (formData.subject === 'Career Oppurtunities') {
      message = 'Thank you for submitting. Please proceed to send your resume/cv to our email address.';
    }

    setThankYouMessage(message);
    setShowThankYouModal(true);
    sendMail(formData.subject, formData.message, formData.firstName, formData.phone);

    // Clear the form data
    setFormData({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      subject: 'General Enquiry',
      message: '',
    });

  };
  
  // Function to check if the email is valid
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const closeThankYouModal = () => {
    setShowThankYouModal(false);
  };

  return (
    <footer className="footer">
      <h1>LET'S BUILD FUTURE TOGETHER</h1>
      <div className="footer-content">
        <div className="contact-form">
          <h3>CONTACT US</h3>

          <form onSubmit={handleSubmit}>
          <div className="contact-form-subject">
              <label>SUBJECT:</label>
              <select
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                className="custom-dropdown"
              >
                <option value="General Enquiry">General Enquiry</option>
                <option value="Career Oppurtunities">Career Opportunities</option>
              </select>
            </div>
            <div className="contact-form-row">
              <input
                className="contact-form-input"
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="FIRST NAME"
              />
              <input
                className="contact-form-input"
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder="LAST NAME"
              />
            </div>
            <div className="contact-form-row">
              <input
                className="contact-form-input"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="E-MAIL ADDRESS"
              />
              <input
                className="contact-form-input"
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="PHONE NUMBER"
              />
            </div>
            <textarea
              className="contact-form-message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="YOUR MESSAGE"
              id="message"
            />
            <div className="submit-button">
              <button type="submit">SUBMIT</button>
            </div>
          </form>
        </div>
      
        {showThankYouModal && (
            <div className="thank-you-modal">
              <div className="thank-you-message">{thankYouMessage}</div>
              <button onClick={closeThankYouModal}>Close</button>
            </div>
          )}

        <div className='footer-responsive-box'>
          <div className="footer-general-info">
            <div className="footer-general-info-content">
              <h3>GENERAL</h3>
              <a href="mailto:goodasiamarketing@gmail.com">goodasiamarketing@gmail.com</a>
              <a href="tel:05-6922233">05-6922233</a>
              <h3>ADDRESS</h3>
              <p>Lot 14794, Jalan C, Kampung Acheh, 32000 Sitiawan Perak Darul Ridzuan.</p>
            </div>
            <div className="footer-social-icons">
              <a href="javascript:void(0)" className="footer-social-icon"><FaFacebook className="footer-social-icon-logo"/></a>
              <a href="https://www.instagram.com/mkgac_development?igsh=M3dpc2NicjJ0Y3Nk&utm_source=qr" className="footer-social-icon-active"><FaInstagram className="footer-social-icon-logo-active"/></a>
              <a href="javascript:void(0)" className="footer-social-icon"><FaYoutube className="footer-social-icon-logo"/></a>
              <a href="javascript:void(0)" className="footer-social-icon"><FaTwitter className="footer-social-icon-logo"/></a>
            </div>
          </div>

          <div className="footer-navigation">
            <div className="footer-logo"><img src={logo}/></div>
            <div className='footer-nav-items'>
              <div className="footer-nav-item"><Link to="/">HOME</Link></div>
              <div className="footer-nav-item"><Link to="/career">CAREER</Link></div>
              <div className="footer-nav-item"><Link to="/news">NEWS</Link></div>
              <div className="footer-nav-item"><Link to="/projects">PROJECTS</Link></div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="footer-text">
        <div className="copyright">
          &copy; 2023 GOOD ASIA CONSTRUCTION. All Rights Reserved.
        </div>
        <div className="privacy-terms">
          <a href="javascript:void(0)">Privacy Terms</a> | <a href="javascript:void(0)">Terms of Sale</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
