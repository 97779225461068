import React, { useEffect, useState } from 'react';
import { FaArrowUp, FaArrowDown, FaArrowRight,FaArrowLeft } from 'react-icons/fa';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Home.css';
import projectData from './ProjectData';
import NumberCounter from '../pages/NumberCounter';

import processImage1 from '../images/process-image-1.png';
import processImage2 from '../images/process-image-2.png';
import processImage3 from '../images/process-image-3.png';
import projectImage from '../images/project-1.jpg';
import client1 from '../images/animoji-1.png';
import client2 from '../images/animoji-2.png';
import client3 from '../images/animoji-3.png';
import client4 from '../images/animoji-4.png';
import client5 from '../images/animoji-5.png';
import image1 from '../images/1st.png'; 
import image2 from '../images/2nd.png'; 
import image3 from '../images/3rd.png'; 
import mainImage from '../images/image.png'; 
import num1 from '../images/num-1.svg';
import num2 from '../images/num-2.svg';
import num3 from '../images/num-3.svg';

const moneyIcon = <img src={num1} alt="Money Icon" />;
const cubeIcon = <img src={num2} alt="Cube Icon" />;
const cropIcon = <img src={num3} alt="Crop Icon" />;

function Home() {
  const [expandedTabs, setExpandedTabs] = useState([true, false, false]); // First tab is expanded by default

  const toggleTab = (index) => {
    setExpandedTabs((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };
    
  const tabContent = [
    {
      title: 'Design Review & Development',
      shortDescription: 'The genesis of our construction projects begins with a fresh perspective, as if each project were a new canvas waiting to be transformed into a work of art. Our process of design review and development encapsulates this creative journey.',
      status: 'on-going',
      year: '2023',
      image: processImage1,
    },
    {
      title: 'Quality Assurance',
      shortDescription: "Our commitment to quality and safety is unwavering. We adhere to industry regulations, conduct stringent quality checks, and prioritize safety throughout every project. Your investment is not just a structure; it's peace of mind.",
      status: 'completed',
      year: '2022',
      image: processImage2,
    },
    {
      title: 'Sustainable Solutions',
      shortDescription: 'We embrace eco-friendly materials and advanced technologies to create sustainable structures that stand the test of time. Our commitment to innovation ensures that your project is both environmentally responsible and forward-thinking.',
      status: 'planned',
      year: '2024',
      image: processImage3,
    },
  ];
            
  const onGoingProjects = projectData.filter(project => project.category === "On-going");

  const [currentIndex, setCurrentIndex] = useState(0);
          
  const handleNext = () => {
    // setCurrentIndex((prevIndex) => (prevIndex + 1) % projects.length);
    setCurrentIndex((currentIndex + 1) % onGoingProjects.length);

  };
        
  const handlePrev = () => {
    // setCurrentIndex((prevIndex) => (prevIndex - 1 + projects.length) % projects.length);
    setCurrentIndex((currentIndex - 1 + onGoingProjects.length) % onGoingProjects.length);

  };
  useEffect(() => {
    const carousel = document.querySelector('.carousel-wrapper');
    const cardWidth = document.querySelector('.project-card').offsetWidth;
    carousel.style.transform = `translateX(${-currentIndex * cardWidth}px)`;
  }, [currentIndex]);
          
  const clientDatabase = [  
    {
      id: 'clientA',   
      name: 'United Allied Development',   
      title: 'Job Title, Company',
      feedback: "I hired Good Asia Construction and they exceeded my expectations in every way. The team was professional, efficient, and the quality of work is exceptional. I couldn't be happier with the result.",
      picture: client1,
    },  
    {
      id: 'clientB',
      name: 'DBE Poultry',
      title: 'Job Title, Company',
      feedback: 'Working with Good Asia Construction was a seamless experience from start to finish. They communicated with us every step of the way, and their attention to detail is commendable. I highly recommend them for any construction or development project.',
      picture: client2,
    },
    {
      id: 'clientC',
      name: 'Eclipse Property',
      feedback: 'I recently used Good Asia Construction for a commercial construction project, and I was thoroughly impressed with their expertise. They completed the project on time and within budget, and their dedication to client satisfaction is evident. I look forward to partnering with them on future projects',
      picture: client3,
    },
    {
      id: 'clientD',
      name: 'Smart Returns',
      feedback: 'At first, I was concerned about the disruption a renovation might cause, but Good Asia Construction made the process easy and stress-free. Their team was respectful of my space, and the final result was a beautiful, functional site that exceeded my vision',
      picture: client4,
    },
    {
      id: 'clientE',
      name: 'Binari Maju',
      feedback: "I entrusted Good Asia Construction with a critical development project, and I'm pleased to say that they delivered outstanding results. Their professionalism, commitment to quality, and ability to solve complex challenges sets them apart. I'm a satisfied customer and would choose them again.",
      picture: client5,
    },
  ];
  
  const [activeClient, setActiveClient] = useState(clientDatabase[0]); // Set the first client as active by default

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api-gocontent.33digitec.xyz/api/pscontpf/get_content?type=ANN', {
          headers: {
            'api-key': 'c871651f-bdf3-4795-b826-cc3cfb80075a',
            'web-key': '1a7184e5-e3de-4ee4-8a4e-9810c2da9b15',
          },
        });
        
        setData(response.data?.message ?? []);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

    return (

      <div className='home-section'>
        <div className='landing-section-content'>
          <h2 className="landing-title">BUILDING</h2>
          <h1 ><em>DREAMS</em></h1>
          <h3 className="landing-subtitle">ONE BRICK AT A TIME!</h3>
          <p className="landing-description">With our team of skilled professionals, we transform your vision into reality, paying meticulous attention to every detail along the way.</p>
        </div>

        <div className="about-section">
          <h2 className="about-title">ABOUT THE COMPANY</h2>
          <h3 className="about-subtitle">Building Dreams</h3>
          <p className="about-description">
            Founded in 2002, we're a construction company dedicated to bringing vision to 
            life. Quality, innovation, and community impact drive everything we build.
          </p>

          <div className="number-counter-wrapper">
            <NumberCounter endValue={2} duration={1000} prefix="RM1." suffix=" billion" text="Completed and on-going projects worth" icon={moneyIcon}/>
            <NumberCounter endValue={6926} duration={1000} prefix="" suffix=" houses" text="Total of handed-over houses" icon={cubeIcon}/>
            <NumberCounter endValue={988} duration={1000} prefix="" suffix=" acres" text="Total of developed lands" icon={cubeIcon}/>
          </div>
            
          <img src={mainImage} className="main-image" alt="Main Image"/>

          <div className="about-images">
            <img src={image1} className="about-image" alt="Description of the image" />
            <img src={image2} className="about-image" alt="Description of the image" />
            <img src={image3} className="about-image" alt="Description of the image" />
          </div>

          <button className="explore-more-button">
            <Link to="/about">Explore More</Link>
            <FaArrowRight className="explore-more-arrow"/>
          </button>

        </div>

        <section className="project-section">
          <h2>Explore some of our current projects</h2>
          <div className='project-section-subtitle'>
            <p>Current Projects</p>
            <div clasasName="prev-next-button">
              <FaArrowLeft className="prev-next-arrow" onClick={handlePrev}/>
              <FaArrowRight className="prev-next-arrow" onClick={handleNext}/>
            </div>
          </div>
      
          <div className="project-carousel">
            {/* <div className="carousel-wrapper" style={{ transform: `translateX(calc(-${currentIndex * 30}%))`}}> */}
            <div className="carousel-wrapper">
              {onGoingProjects.map((project, index) => (
                  <div
                    key={project.id}
                    className={`project-card ${index === currentIndex ? 'active' : ''}`}
                  >
                    <img src={project.backgroundImage} alt={project.name} className="project-image" />
                    <div className="project-card-description">
                      <h3 className="project-name">{project.name}</h3>
                      <p className="project-type">{project.type}</p>
                    </div>
                    <button className="project-read-more">
                      <Link to={{pathname: `/projects/${project.id}`,}}>
                        Read More
                      </Link>
                      <FaArrowRight className="project-read-more-arrow" />
                    </button>
                  </div>
                ))}
            </div>
          </div>

          <div className='view-all-wrapper'> 
            <button className='view-all-button'>
              <Link to="/projects">View All</Link>
                <FaArrowRight className="view-all-arrow" />
            </button>
          </div>
        </section>

        <section className="client-section">
          <h2>WHAT OUR <br/> CLIENTS SAY</h2>
              
          <div className="client-tabs">
            {clientDatabase.map(client => (
              <div
                key={client.id}
                className={`client-tab ${activeClient.id === client.id ? 'active' : ''}`}
                onClick={() => setActiveClient(client)}
              >
                {client.name}
              </div>
            ))}
          </div>
          
          <div className="client-feedback">
            <p className="client-feedback-text">"{activeClient.feedback}"</p>
          </div>
              
          <div className="client-info">
            <img className="client-picture" src={activeClient.picture} alt={activeClient.name} />
            <div className="client-details">
              <h3 className="client-name">{activeClient.name}</h3>
            </div>
          </div>
        </section>

        <div className="news-update-section">
          <h2>News & Updates</h2>

          <div className='news-update-section-subtitle'>
            <p>The development of clean energy is still being carried out to overcome these obstacles and make this energy source an environmentally friendly future solution</p>
            <button className="news-update-read-more">
            <Link to="/news">Read More</Link>
               <FaArrowRight className="news-update-read-more-arrow" />
            </button>
          </div>

          <div className="newsHomeCardWrapper">
            {data.map((item) => (
              <div key={item.id} className="newsHomeCard">
                <h1 className="newsHomeCardType">{item.pscontyp}</h1>
                <h2 className="newsHomeCardTitle">{item.psconttl}</h2>
                <p className="newsHomeCardContent">{item.psconaut}</p>
                    
                {item.documents && item.documents.length > 0 && (
                  <div className="newsHomeCardImageContainer">
                    {item.documents.map((document) => (
                      <img
                        key={document.id}
                        src={'https://api-gocontent.33digitec.xyz/api/document/detail?type=7&document='+document.psdocfnm}
                        className='newsHomeCardImage'
                      />
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div> 
        </div>
      </div>
  );
}

export default Home;
