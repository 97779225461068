import React, { useState, useEffect } from 'react';
import { FaArrowUp, FaArrowDown, FaArrowRight,FaArrowLeft } from 'react-icons/fa';

import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import projectData from './ProjectData';
import './Projects.css'; 

function Projects() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialCategory = queryParams.get('category') || 'All';
    // const initialCategory = queryParams.get('category');

    const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const filteredProjects = projectData.filter((project) => {
      if (selectedCategory === 'All') {
        return true;
      }
      return project.category === selectedCategory;
    });

    useEffect(() => {
      const categoryFromQuery = queryParams.get('category') || 'All';
      setSelectedCategory(categoryFromQuery);
    }, [location.search]);
    
  return (
    <div className="projects-page">
      <h2>Projects</h2>
      <div className='project-page-category-button'>
        <button
          onClick={() => setSelectedCategory('On-going')}
          className={selectedCategory === 'On-going' ? 'active' : ''}
        >
          ON-GOING
        </button>
        <button
          onClick={() => setSelectedCategory('Future')}
          className={selectedCategory === 'Future' ? 'active' : ''}
        >
          FUTURE
        </button>
        <button
          onClick={() => setSelectedCategory('Completed')}
          className={selectedCategory === 'Completed' ? 'active' : ''}
        >
          COMPLETED
        </button>
      </div>

      <div className="project-page-cards">
        {filteredProjects.map((project) => (
          <div className='project-page-card' key={project.id}>
            <div className="project-page-card-content">
            <Link to={{pathname: `/projects/${project.id}`,}}>
              {/* <h3>{project.name.toUpperCase()}</h3> */}
              <h3>{project.name}</h3>

              <div className="project-page-details">
                <div className="project-page-place-type">
                  <div className="project-page-place">{project.place}</div>
                  <div className="project-page-type">{project.doc}</div>
                </div>
                <div className="project-page-view-more-button">
                  <Link to={{pathname: `/projects/${project.id}`,}} className="project-page-view-more-link">View More
                  <FaArrowRight className="project-page-view-more-arrow" />
                  </Link>
                </div>
              </div>
              </Link>

            </div>
          </div>
        ))}
      </div>

    </div>
  );
}

export default Projects;
