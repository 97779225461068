import React, { useEffect, useState } from 'react';
import { FaArrowUp, FaArrowDown, FaArrowRight,FaArrowLeft } from 'react-icons/fa';
import './About.css';
import NumberCounter from '../pages/NumberCounter';

import processImage1 from '../images/process-image-1.png';
import processImage2 from '../images/process-image-2.png';
import processImage3 from '../images/process-image-3.png';
import projectImage from '../images/project-1.jpg';

import image1 from '../images/1st.png'; 
import image2 from '../images/2nd.png'; 
import image3 from '../images/3rd.png'; 
import mainImage from '../images/image.png'; 
import num1 from '../images/num-1.svg';
import num2 from '../images/num-2.svg';
import num3 from '../images/num-3.svg';

const moneyIcon = <img src={num1} alt="Money Icon" />;
const cubeIcon = <img src={num2} alt="Cube Icon" />;
const cropIcon = <img src={num3} alt="Crop Icon" />;

function Home() {
    const [expandedTabs, setExpandedTabs] = useState([true, false, false]); // First tab is expanded by default
  
    const toggleTab = (index) => {
      setExpandedTabs((prevExpanded) => {
        const newExpanded = [...prevExpanded];
        newExpanded[index] = !newExpanded[index];
        return newExpanded;
      });
    };
      
    const tabContent = [
      {
        title: 'Design Review & Development',
        shortDescription: 'The genesis of our construction projects begins with a fresh perspective, as if each project were a new canvas waiting to be transformed into a work of art. Our process of design review and development encapsulates this creative journey.',
        status: 'on-going',
        year: '2023',
        image: processImage1,
      },
      {
        title: 'Quality Assurance',
        shortDescription: "Our commitment to quality and safety is unwavering. We adhere to industry regulations, conduct stringent quality checks, and prioritize safety throughout every project. Your investment is not just a structure; it's peace of mind.",
        status: 'completed',
        year: '2022',
        image: processImage2,
      },
      {
        title: 'Sustainable Solutions',
        shortDescription: 'We embrace eco-friendly materials and advanced technologies to create sustainable structures that stand the test of time. Our commitment to innovation ensures that your project is both environmentally responsible and forward-thinking.',
        status: 'planned',
        year: '2024',
        image: processImage3,
      },
    ];
  return (

    <div className='about-us-section'>

        <div className="about-section">
            <h2 className="about-title">ABOUT THE COMPANY</h2>
            <h3 className="about-subtitle">Building Dreams</h3>
            <p className="about-description">
            Founded in 2002, we're a construction company dedicated to bringing vision to 
            life. Quality, innovation, and community impact drive everything we build.
            </p>

            <div className="number-counter-wrapper">
            <NumberCounter endValue={93} duration={1000} prefix="RM2." suffix=" billion" text="Completed and on-going projects worth" icon={moneyIcon}/>
            <NumberCounter endValue={6926} duration={1000} prefix="" suffix=" acres" text="Total of handed-over houses" icon={cubeIcon}/>
            <NumberCounter endValue={988} duration={1000} prefix="" suffix=" acres" text="Total of developed lands" icon={cropIcon}/>
            </div>
    
            <img src={mainImage} className="main-image" alt="Main Image"/>

            <div className="about-images">
                <img src={image1} className="about-image" alt="Description of the image" />
                <img src={image2} className="about-image" alt="Description of the image" />
                <img src={image3} className="about-image" alt="Description of the image" />
            </div>

            <div>
                <h2 className="about-us-title">Vision</h2>
                <p className="about-us-description">
                    Our vision is to be a leading force in development and construction, delivering innovative and 
                    sustainable solutions that redefine urban landscapes while fostering community growth. We aspire 
                    to set new industry benchmarks, ensuring excellent in every project, and creating environments 
                    that inspire and endure. We aim to build a better tomorrow through thoughtful development and construction.
                </p>

                <h2 className="about-us-title">Mission</h2>
                <p className="about-us-description">
                    Our mission is simple: to build homes that stand as foundations for lasting happiness, where every brick embodies 
                    warmth, and every room echoes the joy of family life, We're dedicated to turning houses into cherished homes.
                </p>
                <h2 className="about-us-title">Core Value</h2>
                <p className="about-us-description">
                    Our core values revolve around honesty, creativity, teamwork, and a relentless pursuit of quality. These 
                    principles guide us in building not just structures, but enduring relationships and a legacy of excellence.
                </p>
            </div>
        </div>

        <section className="process-section">
        <h2 className="process-title">Our Process to Build the Future</h2>
        <p className="process-description">
        Every day we develop products and projects from various clients around the world with various 
        platforms ranging from websites, mobile applications and development. We have an experienced 
        team in the field by doing management to make your project structured
        </p>

        {tabContent.map((content, index) => (
        <div className="expandable-tab" data-expanded={expandedTabs[index]} key={index}>
            <div className="expandable-header" onClick={() => toggleTab(index)}>
            <h3 className="expandable-title">{content.title}</h3>
            <button className="expand-button">
                {expandedTabs[index] ? <FaArrowDown className="arrow-icon" /> : <FaArrowUp className="arrow-icon" />}
            </button>
            </div>
            
            {expandedTabs[index] && (
            <div className="expandable-content">
                <img src={content.image} alt={`Image ${index + 1}`} className="expandable-image" />
                <div className="expandable-description">
                <div className="expandable-short-description">{content.shortDescription}</div>
                </div>
            </div>
            )}
        </div>
        ))}
        </section>
    </div>  

    );
    }

export default Home;
