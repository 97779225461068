import axios from 'axios';

export async function fetchNewsData() {
  try {
    const response = await axios.get(
      'https://api-gocontent.33digitec.xyz/api/pscontpf/get_content?type=ANN',
      {
        headers: {
          'api-key': 'c871651f-bdf3-4795-b826-cc3cfb80075a',
          'web-key': '1a7184e5-e3de-4ee4-8a4e-9810c2da9b15',
        },
      }
    );
    return response.data?.message ?? [];
  } catch (error) {
    console.error(error);
    return [];
  }
}
