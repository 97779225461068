import React from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Career from './pages/Career';
import News from './pages/News';
import Projects from './pages/Projects';
import ProjectDetail from './pages/ProjectDetail';
import ScrollToTop from './ScrollToTop'; // Import the custom ScrollToTop component

function App() {
  return (
    <Router>
      <ScrollToTop /> 
      <Navbar/>
      <Routes scrollTop="true">
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/career" element={<Career />} />
        <Route path="/news" element={<News />} />
        <Route path="/projects" element={<Projects />} />
        <Route exact path="/projects/:id" element={<ProjectDetail/>} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;

