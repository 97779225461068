import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import projectData from './ProjectData';
import ImageGallery from '/Users/xinying/Desktop/construction-company-website/src/components/ImageGallery.js';
import './ProjectDetail.css';

function ProjectDetail() {
  const { id } = useParams();
  const project = projectData.find(project => project.id === parseInt(id));

  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);

  // Function to toggle the description expansion
  const toggleDescriptionExpansion = () => {
    setDescriptionExpanded(!isDescriptionExpanded);
  };

  if (!project) {
    return <div>Project not found</div>;
  }


  if (id === "28") {
    // console.log (project.data);

    return (
      <div className='custom-project-details-page'>
        <div className='project-details-header' key={project.id}>
          <div className="project-details-header-content" style={{ backgroundImage: `url(${project.backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <h3>{project.name.toUpperCase()}</h3>
            <p>{project.description}</p>
            <div className="project-details-header-row">
              <div className="project-details-header-row-content">
                <div className="custom-project-details-place">{project.place}</div>
                <div className="custom-project-details-type">{project.doc}</div>
              </div>
              <div className="project-details-header-row-content">
                <div className="custom-project-details-client">{project.client}</div>
              </div>
            </div>
          </div>

          <div className='custom-project-details-card'>
            <h2>PROJECT DETAILS</h2>
            <div className="custom-project-details-grid">

              <div className="custom-project-details-header-row">
                <h3 className='description-column'><strong>Description</strong></h3>
                <h3><strong>Name</strong></h3>
                {/* <h3><strong>Capital</strong></h3> */}
              </div>
          
              {project.data.map((item, index) => (
                <div key={index} className="custom-project-details-data-row">
                  <p className='description-column'>{item.description}</p>
                  <p className='name-content'>{item.name}</p>
                  {/* <p className='capital-content'>{item.capital}</p> */}
                </div>
              ))}
            </div>

            {project.images.length > 0 && ( // Check if there are images
              <div className='project-details-card-gallery'>
                <h3>GALLERY</h3>
                <ImageGallery images={project.images} />
              </div>
            )}

          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='project-details-page'>
      <div className='project-details-header' key={project.id} >
        <div className="project-details-header-content" style={{ backgroundImage: `url(${project.backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <h3>{project.name.toUpperCase()}</h3>
          {/* <p>{project.description}</p> */}
          <div className="project-details-header-row">
            <div className="project-details-header-row-content">
              <div className="project-details-place">{project.place}</div>
              <div className="project-details-type">{project.doc}</div>
            </div>
          </div>
        </div>

        <div className='project-details-card'>
          <h2>PROJECT DETAILS</h2>
          <div className='project-details-card-content'>
            <div className='project-details-card-left'> 
              {/* <h3>{project.name.toUpperCase()}</h3>  */}
              <p>{project.description}</p>
            </div>

            <div className='project-details-card-right'>
              <div className="project-detail-row">
                <div className="project-detail-label">CLIENT</div>
                <div className="project-detail-data">{project.client}</div>
              </div>

              {/* <div className="project-detail-row">
                <div className="project-detail-label">PROJECT CAPITAL</div>
                <div className="project-detail-data">{project.capital}</div>
              </div> */}

              <div className="project-detail-row">
                <div className="project-detail-label">DATE COMPLETED</div>
                <div className="project-detail-data">{project.doc}</div>
              </div>

              <div className="project-detail-row">
                <div className="project-detail-label">LOCATION</div>
                <div className="project-detail-data">{project.place}</div>
              </div>

            </div>
          </div>

          {project.images.length > 0 && ( // Check if there are images
            <div className='project-details-card-gallery'>
              <h3>GALLERY</h3>
              <ImageGallery images={project.images} />
            </div>
          )}
            
        </div>
      </div>
    </div>
  );
}

export default ProjectDetail;
