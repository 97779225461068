import React, { useState } from 'react';
import './ImageGallery.css';

function ImageGallery({ images }) {
  const [showFullImage, setShowFullImage] = useState(null);

  const openFullImage = (imageIndex) => {
    setShowFullImage(imageIndex);
  };

  const closeFullImage = () => {
    setShowFullImage(null);
  };

  return (
    <div className="project-image-gallery">
      {images.map((image, index) => (
        <div key={index} className="project-image-container">
          <img
            src={image}
            alt={`Image ${index + 1}`}
            onClick={() => openFullImage(index)}
          />
        </div>
      ))}
      {showFullImage !== null && (
        <div className="project-full-image-overlay" onClick={closeFullImage}>
          <div className="project-full-image-container">
            <img
              src={images[showFullImage]}
              alt={`Image ${showFullImage + 1}`}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageGallery;
