const projectData = [
  {
    id: 28,
    name: 'PROJECT TELUK INTAN',
    place: 'TELUK INTAN',
    client: 'RANTAU URUSAN (M) SDN.BHD.',
    capital: 'RM 75,076,000.00',
    doc: 'TO BE ADVISED',
    category: 'On-going', 
    backgroundImage: '/images/28/28-1.jpeg',
    images: [
      '/images/28/28-1.jpeg',
      '/images/28/28-2.jpeg',
      '/images/28/28-3.jpeg',
      '/images/28/28-4.jpeg',
      '/images/28/28-5.jpeg',
      '/images/28/28-6.jpeg',
      '/images/28/28-7.jpeg',
      '/images/28/28-8.jpeg',
    ],
    data: [
      {
        name: 'MAJOR INDRASTRUCTURE WORKS (PHASE 1-PHASE 7)',
        description: 'CADANGAN SKIM PEMBANGUNAN BERCAMPUR,DI ATAS LOT 59327 HINGGA 59402,MUKIM DURIAN SEBATANG, DAERAH HULIR PERAK,PERAK DARUL RIDZUAN UNTUK TETUAN TARAF NUSANTARA SDN BHD(LUAS KAWASAN 1,091.03 EKAR)',
        capital: "RM 75,076,000",
        // client: "Client 1",
      },
      {
        name: 'INFRASTRUCTURE WORKS',
        description: 'CADANGAN MEMBINA DAN MEYIAPKAN 990UNIT RUMAH TERES SATU TINGKAT DI ATAS LOT 59342 – LOT 59349,LOT 59364-LOT 59349& LOT59374-LOT 59379, MUKIM DURIAN SEBATANG,DAERAH HILIR PERAK, PERAK DARUL RIDZUAN UNTUK TETUAN TARAF NUSANTARA SDN.BHD. (FASA 1-PACKAGE 1)',
        capital: "RM 11,880,000",
      },
      {
        name: 'INFRASTRUCTURE WORKS',
        description: 'CADANGAN MEMBINA DAN MEYIAPKAN 990UNIT RUMAH TERES SATU TINGKAT DI ATAS LOT 59342 – LOT 59349,LOT 59364-LOT 59349& LOT59374-LOT 59379, MUKIM DURIAN SEBATANG,DAERAH HILIR PERAK, PERAK DARUL RIDZUAN UNTUK TETUAN TARAF NUSANTARA SDN.BHD. (FASA 1-PACKAGE 2)',
        capital: "RM 10,592,000",
      },
      {
        name: 'INFRASTRUCTURE WORKS',
        description: 'CADANGAN PEMBANGUNAN SKIM PERUMAHAN BAGI: 1) 2,574 UNIT RUMAH TERES SETINGKAT JENIS A DI PLOT 1 -2406 & 2500-2667 2) 93 UNIT RUMAH TERES SETINGKAT JENIS B DI PLOT 2407-2499 3) 3 UNIT PENCAWANG ELEKTRIK DI PLOT 2668 -2670 DI ATAS LOT 59372, LOT59373, LOT59394 HINGGA LOT 59409 DAN TANAH KERAJAAN, MUKIM DURIAN SENBATANG, DAERAH HILIR PERAK, PERAK DARUL RIDZUAN UNTUK TETUAN TARAF NUSANTARA SDN BHD (PHASE2)',
        capital: "RM 37,604,700",
      },
      {
        name: 'EARTHWORK',
        description: 'CADANGAN PEMBANGUNAN SKIM PERNIAGAAN DAN PERUMAHAN DI ATAS LOT 59342-LOT 59349, LOT 59364 – LOT 59371 & LOT 59374 – LOT 59379 & SIMPANAN JALAN 20 KAKI MUKIM DURIAN SEBATABG, DAERAH HILIR PERAK,PERAK DARUL RIDZUAN UNTUK TETUAN TARAF NUSANTARA SDN.BHD.(PHASE 1 – EARTHWORK)',
        capital: "RM 3,706,800",
      },
      {
        name: 'PILING WORK',
        description: 'CADANGAN MEMBINA DAN MENYIAPKAN 990UNIT RUMAH TERES SATU TINGKAT DI ATAS LOT 59342 – LOT 59349, LOT59364 – LOT59349 & LOT 59374 – LOT59379, MUKIM DURIAN SEBATANG , DAERAH HILIR PERAK, PERAK DARUL RIDZUAN UNTUK TETUAN TARAF NUSANTARA SDN BHD (PHASE 1 –PACKAGE 1 PILING WORK)',
        capital: "RM 3,861,000",
      },
      {
        name: 'BUILDING WORK (990UNITS)',
        description: 'CADANGAN MEMBINA DAN MENYIAPKAN 990UNIT RUMAH TERES SATU TINGKAT DI ATAS LOT 59342 – LOT 59349, LOT59364 – LOT59349 & LOT 59374 – LOT59379, MUKIM DURIAN SEBATANG , DAERAH HILIR PERAK, PERAK DARUL RIDZUAN UNTUK TETUAN TARAF NUSANTARA SDN BHD (PHASE 1-PACKAGE 1 BUILDING WORK)',
        capital: "RM 54,500,000",
      },
      {
        name: 'PILING WORK',
        description: 'CADANGAN MEMBINA DAN MENYIAPKAN 497 UNIT RUMAH TERES SATU TINGKAT DAN 356 UNIT RUMAH KLUSTER SATU TINGKAT DI ATAS LOT 59342 – LOT 59349 LOT 59364 – LOT 59349 & LOT 59374- LOT59379 MUKIM DURIAN SEBATANG,DAERAH HILIR PERAK, PERAK DARUL RIDZUAN UNTUK TETUAN TARAF NUSANTARA SDN BHD. (PHASE 1 –PACKAGE2 PILING WORK)',
        capital: "RM 3,326,700",
      },
      {
        name: 'BUILDING WORKS',
        description: 'CADANGAN MEMBINA DAN MENYIAPKAN 497 UNIT RUMAH TERES SATU TINGKAT DAN 356 UNIT RUMAH KLUSTER SATU TINGKAT DI ATAS LOT 59342 – LOT 59349 LOT 59364 – LOT 59349 & LOT 59374- LOT59379 MUKIM DURIAN SEBATANG,DAERAH HILIR PERAK, PERAK DARUL RIDZUAN UNTUK TETUAN TARAF NUSANTARA SDN BHD. (PHASE 1 –PACKAGE 2 BUILDING WORKS)',
        capital: "RM 49,407,000",
      },
      {
        name: 'EARTHWORK',
        description: 'CADANGAN PEMBANGUNAN SKIM PERNIAGAAN DAN PERUMAHAN BAGI: 1) 2,574 UNIT RUMAH TERES SETINGKATJENIS A DI PLOT 1 -2406 &2500-2667, 2) 93 UNIT RUMAH TERES SETINGKAT JENIS B DI PLOT 2407-2499, 3) 3UNIT PENCAWANG ELEKTRIK DI PLOT 2668-2670 DI ATAS LOT 59372, LOT59373, LOT59394 – LOT59409 & TANAH KERAJAAN MUKIM DURIAN SEBATANG, DAERAH HILIR PERAK, PERAK DARUL RIDZUAN UNTUK TETUAN TARAF NUSANTARA SDN BHD (PHASE 2 EARTHWORK)',
        capital: "RM 1,400,000",
      },
      {
        name: 'PILING WORKS',
        description: 'CADANGAN PEMBANGUNAN SKIM PERNIAGAAN DAN PERUMAHAN BAGI: 1) 2,574 UNIT RUMAH TERES SETINGKATJENIS A DI PLOT 1 -2406 &2500-2667, 2) 93 UNIT RUMAH TERES SETINGKAT JENIS B DI PLOT 2407-2499, 3) 3UNIT PENCAWANG ELEKTRIK DI PLOT 2668-2670 DI ATAS LOT 59372, LOT59373, LOT59394 – LOT59409 & TANAH KERAJAAN MUKIM DURIAN SEBATANG, DAERAH HILIR PERAK, PERAK DARUL RIDZUAN UNTUK TETUAN TARAF NUSANTARA SDN BHD (PHASE 2 PILING WORKS)',
        capital: "RM 10,641,330",
      },
      {
        name: 'BUILDING WORKS',
        description: 'CADANGAN PEMBANGUNAN SKIM PERNIAGAAN DAN PERUMAHAN BAGI: 1) 2,574 UNIT RUMAH TERES SETINGKATJENIS A DI PLOT 1 -2406 &2500-2667, 2) 93 UNIT RUMAH TERES SETINGKAT JENIS B DI PLOT 2407-2499, 3) 3UNIT PENCAWANG ELEKTRIK DI PLOT 2668-2670 DI ATAS LOT 59372, LOT59373, LOT59394 – LOT59409 & TANAH KERAJAAN MUKIM DURIAN SEBATANG, DAERAH HILIR PERAK, PERAK DARUL RIDZUAN UNTUK TETUAN TARAF NUSANTARA SDN BHD (PHASE 2 BUILDING WORKS)',
        capital: "RM 146,735,000",
      },
    ]
  },
  {
    id: 29,
    name: 'KOMPLEKS PERNIAGAAN HOTEL DAN DATARAN',
    place: 'KUALA BERANG',
    description: 'MEREKEBENTIK MEMBINA DANMENYIAPKAN KOMPLEKS PERNIAGAAN HOTEL DAN DATARAN DI MASJID SULTAN MAHMUD KUALA BERANG',
    client: 'MSI VISION SDN BHD',
    capital: 'RM 21,000,000.00',
    doc: 'TO BE ADVISED',
    category: 'On-going', 
    backgroundImage: '/images/29/29-1.jpeg',
    images: [
      '/images/29/29-1.jpeg',
      '/images/29/29-2.jpeg',
      '/images/29/29-3.jpeg',
      '/images/29/29-4.jpeg',
      '/images/29/29-5.jpeg',
      '/images/29/29-6.jpeg',
    ],
  }, 
  {
    id: 30,
    name: '30 UNITS OF SINGLE STOREY SEMI DETACHED HOUSE & 1 UNIT TNB COMPACT SUB-STATION',
    place: 'PERAK',
    description: 'The Execution, Construction And Completion of 30 Units of Single Storey Semi Detached House (Plot 1 - 30) & 1 Unit TNB Compact Sub-Station on GM4638 Lot No.2248, Mukim Sitiawan, Daerah Manjung, Perak For Weshwin Development Sdn Bhd.',
    client: 'WESHWIN DEVELOPMENT SDN BHD',
    capital: 'RM 4,381,000',
    doc: 'TO BE ADVISED',
    category: 'On-going', 
    backgroundImage: '/images/30/30-1.jpeg',
    images: [
      '/images/30/30-1.jpeg',
      '/images/30/30-2.jpeg',
      '/images/30/30-3.jpeg',
      '/images/30/30-4.jpeg',
    ],
  }, 
  {
    id: 31,
    name: 'EARTHWORKS FOR LARGE SCALE SOLAR PHOTOVOLTAIC PLANT DEVELOPMENT',
    place: 'PERAK',
    description: 'SUB-CONTRACT FOR EARTHWORKS FOR THE DEVELOPMENT OF 50MWAC LARGE SCALE SOLAR (LSS4) PHOTOVOLTAIC PLANT DEVELOPMENT AT LADANG BIKAM, MUKIM BIDOR, DAERAH BATANG PADANG, PERAK DARUL RIDZUAN',
    client: 'FABULOUS SUNVIEW SDN BHD',
    capital: 'RM 770,904.49',
    doc: 'TO BE ADVISED',
    category: 'On-going', 
    backgroundImage: '/images/31/31-7.jpeg',
    images: [
      '/images/31/31-1.jpeg',
      '/images/31/31-2.jpeg',
      '/images/31/31-3.jpeg',
      '/images/31/31-4.jpeg',
      '/images/31/31-5.jpeg',
      '/images/31/31-6.jpeg',
      '/images/31/31-7.jpeg',
    ],
  }, 
  {
    id: 32,
    name: 'EARTHWORKS AND RETAINING WALLS',
    place: 'PERAK',
    description: 'CADANGAN PERUMAHAN DI ATAS PT 265369 (HSD 229470) & PT 265370 (HSD 229471), BANDAR IPOH(S), DAERAH KINTA, PERAK DARUL RIDZUAN.',
    client: 'MACORP HOLDINGS SDN BHD',
    capital: 'RM 3,768,145.10',
    doc: 'TO BE ADVISED',
    category: 'On-going', 
    backgroundImage: '/images/32/32-7.jpeg',
    images: [
      '/images/32/32-1.jpeg',
      '/images/32/32-2.jpeg',
      '/images/32/32-3.jpeg',
      '/images/32/32-4.jpeg',
      '/images/32/32-5.jpeg',
      '/images/32/32-6.jpeg',
      '/images/32/32-7.jpeg',
      '/images/32/32-8.jpeg',
    ],
  }, 
  {
    id: 33,
    name: '121 UNITS OF SINGLE STOREY TERRACE HOUSE AT BIDOR PERMAI',
    place: 'BIDOR PERMAI',
    description: '121 UNITS OF SINGLE STOREY TERRACE HOUSE AT BIDOR PERMAI',
    client: '',
    capital: 'RM 28,955,000.00',
    doc: 'TO BE ADVISED',
    category: 'On-going', 
    backgroundImage: '/images/33/33-1.jpeg',
    images: [
      '/images/33/33-1.jpeg',
    ],
  }, 
  {
    id: 34,
    name: 'VILLA D’POLO AT JALAN RAJA DIHILIR, IPOH',
    place: 'PERAK',
    description: 'CADANGAN SKIM PERUMAHAN (FASA 1) YANG MENGANDUNGI 48 UNIT RUMAH BERKEMBAR DUA TINGKAT DI ATAS PLOT 31 - PLOT 78, SEBAHAGIAN PT 265369 & PT 265370 (23.05 EKAR), BANDAR IPOH(S), DAERAH KINTA, PERAK DARUL RIZUAN',
    client: 'Macorp Holdings Sdn Bhd',
    capital: '',
    doc: 'TO BE ADVISED',
    category: 'On-going', 
    backgroundImage: '/images/34/34-3.jpg',
    images: [
      '/images/34/34-2.jpg',
      '/images/34/34-3.jpg',
      '/images/34/34-4.jpg',
      '/images/34/34-5.jpg',
      '/images/34/34-6.jpg',
    ],
  }, 
  {
    id: 26,
    name: '1005 UNIT RUMAH TERES DUA TINGKAT',
    place: 'PERAK',
    description: 'Cadagan Membina dan Menyiapkan Dan Menyiapkan Skim Perumahan Mampu Milik Fasa 1B Yang Mengandungi 1005 Unit Rumah Teres Dua Tingkat Di Atas Sebahangian GM105129 Lot 16684 Block B Dan C PT 42253 Mukim Sitiawan Daerah Manjung,Perak.',
    client: 'Rantau Urusan (M) Sdn Bhd',
    capital: 'RM 66,500,000.00',
    doc: 'JUL 2020',
    category: 'Completed',
    backgroundImage: '/images/image.png',
    images: [],
  },
  {
    id: 25,
    name: 'GUDUNG BERSERTA 2 UNIT PEJABAT, PONDOK PENGAWAL & KEBUK SAMPAH',
    place: 'SELANGOR',
    description: 'Cadangan Membina Sebuah Gudung Berserta 2 Unit Pejabat, 2 Unit Pondok Pengawal Dan 2 Unit Kebuk Sampah Berkeluasan 5 Ekar Di Atas P637,P646, Sebahagian Lot P638,P645,P636 Dan P647 Precint 6 Jalan FZ4-P6,Port Klang Free Zone/Ks 12, 42920 Pulau Indah, Klang Bandar Diraja, Selangor Darul Ehsan Untuk Tetuan Multiplus Logistics (M) S/B.',
    client: 'Multiplus logistics',
    capital: 'RM 18,000,000.00',
    doc: 'JAN 2020',
    category: 'Completed', 
    backgroundImage: '/images/image.png',
    images: [
      '/images/25/25-1.jpg',
      '/images/25/25-2.jpg',
      '/images/25/25-3.jpg',
      '/images/25/25-4.jpg',
      '/images/25/25-5.jpg',
    ],
  },
  {
    id: 24,
    name: 'LOT 1180(PLOT 1-37) MUKIM LUMUT',
    place: 'PERAK',
    description: 'Cadangan Membina dan Menyiapkan sebuah Preumahan Yang Mengandungi 26 Unit Rumah Teres 1 Tingkat (Plot 1-16 7 28-37) 11 Unit Rumah Teres 2 Tingkat(Plot 17-27) Di Atas Lot 1180(Plot 1-37) Mukim Lumut Daerah Manjung Perak Darul Ridzuan Untuk Tetuan:Manjung Pertiwi Sdn Bhd.',
    client: 'Manjung Pertiwi Sdn Bhd',
    capital: 'RM 4,141,000.00',
    doc: 'JUN 2018',
    category: 'Completed', 
    backgroundImage: '/images/image.png',
    images: [
      '/images/24/24-1.jpg',
      '/images/24/24-2.jpg',
      '/images/24/24-3.jpg',
      '/images/24/24-4.jpg',
    ],
  },
  {
    id: 21,
    name: '2 UNIT RUMAH SEMI-D 1 TINGKAT',
    place: 'PERAK',
    description: 'Cadangan Membina 2 Unit Rumah Semi-D 1 Tingkat, Di Atas Sebahagian Lot 13082,PT 14165 & 14166 Mukim Lumut, Daerah Manjung,Perak Darul.',
    client: 'Yik Wang Development Sdn Bhd',
    capital: 'RM 200,000.00',
    doc: 'JUN 2017',
    category: 'Completed',
    backgroundImage: '/images/image.png',
    images: [],
  },
  {
    id: 22,
    name: 'RUMAH BANGLO SETINGKAT, MUKIM LUMUT',
    place: 'PERAK',
    description: 'Cadangan Membina Dan Menyiapkan Sebuah Rumah Banglo Setingkat, Di Atas PT14164 Mukim Lumut, Daerah Manjung, Perak Darul Ridzuan.',
    client: 'Yik Wang Development Sdn Bhd',
    capital: 'RM 118,000.00',
    doc: 'JUN 2017',
    category: 'Completed',
    backgroundImage: '/images/image.png',
    images: [],
  },
  {
    id: 23,
    name: 'LOT 13083 DAN H.S(M) 2386 PT 14149',
    place: 'PERAK',
    description: 'Cadangan Membina dan Menyiapkan sebuah Skim Perniagaan yang mengandungi 1 Unit Kedai Pejabat 3 ½ tingkat, 1 Unit kedai Pejabat 3 Tingkat dan 8 Unit kedai Pejabat 2 Tingkat, Di atas Lot 13083 Dan H.S(M) 2386 PT 14149, Mukim Lumut Daerah Manjung, Perak Darul Ridzuan.',
    client: 'United Allied Development Sdn Bhd',
    capital: 'RM 1,298,954.00',
    doc: 'JUN 2017',
    category: 'Completed', 
    backgroundImage: '/images/image.png',
    images: [],
  },
  {
    id: 20,
    name: 'MULTIMEDIA DISTRUBUTION & MARKETING SDN BHD',
    place: 'SELANGOR',
    description: 'Cadangan Membina & Sebuah Gudang 1 Tingkat 1 Unit Pondok Pengawai 1 Unit Kebuk Sampah Dan 1 Unit Rumah Pam Di atas Sebahagian Lot67894 (PT7324)10Ekar Jalan FZ-P2,Port Klang Free Zone KS/12 42920 Pulau Indah Klang Bandar Diraja Selangor Darul Ehsan Untuk Tetuan:Multimedia Distribution & Marketing Sdn Bhd Kegunaan:Menyiapkan Barangan Pembinaan ',
    client: 'Multimedia Distribution Sdn Bhd',
    capital: 'RM 15,000,000.00',
    doc: 'JAN 2017',
    category: 'Completed', 
    backgroundImage: '/images/image.png',
    images: [
      '/images/20/20-1.jpg',
      '/images/20/20-2.jpg',
      '/images/20/20-3.jpg',
      '/images/20/20-4.jpg',
      '/images/20/20-5.jpg',
    ],
  },
  {
    id: 17,
    name: 'KEDAI PEJABAT 2 TINGKAT, RUMAH TERAS 2 TINGKAT & 1 TINGKAT, DAN SINGLE CHAMBER TNB SUB STATION',
    place: 'PERAK',
    description: 'Cadangan Membina 14 Unit Kedai Pejabat 2 Tingkat(plot 1-plot 14), 13 Unit rumah teras 2 Tingkat(plot 15-plot 27), 49 Unit rumah Teras 1 Tingkat (plot 28-plot 76)dan 1 Unit single Chamber TNB sub Station Di atas Lot174,176,178 Dan 178,Mukim Lumut Daerah Manjung,Perak.',
    client: 'United Allied Development Sdn Bhd',
    capital: 'RM 7,954,947.80',
    doc: 'MAY 2016',
    category: 'Completed',
    backgroundImage: '/images/image.png',
    images: [
      '/images/17/17-1.jpg',
      '/images/17/17-2.jpg',
      '/images/17/17-3.jpg',
      '/images/17/17-4.jpg',
      '/images/17/17-5.jpg',
      '/images/17/17-6.jpg',
      '/images/17/17-7.jpg',
      '/images/17/17-8.jpg',
      '/images/17/17-9.jpg',
      '/images/17/17-10.jpg',
    ],
  },
  {
    id: 19,
    name: '54 UNIT RUMAH TERES 2 TINGKAT & 27 UNIT RUMAH TERES 1 TINGKAT',
    description: 'Cadangan Membina Dan Menyiapkan Skim Pembangunan Yang Mengandungi 54 unit Rumah Teres dua tingkat (plot28-81) Di atas Lot 49148(PT30536)(Lot52384-Lot 52420) dan 27 Unit Rumah Teres satu Tingkat(plot 1-27) Di atas Lot 49148(PT 30536)(Lot52384-Lot52420) Sitiawan Daerah Manjung Perak Darul Ridzuan.',
    place: 'PERAK',
    client: 'Binari Maju Sdn Bhd',
    capital: 'RM 4,141,000.00',
    doc: 'APR 2016',
    category: 'Completed',
    backgroundImage: '/images/image.png',
    images: [
      '/images/19/19-1.jpg',
      '/images/19/19-2.jpg',
      '/images/19/19-3.jpg',
      '/images/19/19-4.jpg',
    ],
  },
  {
    id: 18,
    name: '6 UNIT KEDAI PEJABAT 2 TINGKAT & 5 UNIT KEDAI PEJABAT 3 TINGKAT',
    place: 'PERAK',
    description: 'Cadangan Membina Dan Menyiapkan 6 Unit kedai pejabat 2 Tingkat (plot 3-Plot 8 PT39280-PT39285) & 5 Unit kedai Pejabat 3 Tingkat(plot 1,2,9,10&11-PT 39278,PT39279,PT39286,PT39287& PT39288)Di atas lot 26101,29019 & Tanah Kerajaan,Mukim Sitiawan Daerah Manjung,Perak. ',
    client: 'Blossom Eastland Sdn Bhd ',
    capital: 'RM 1,145,656.40',
    doc: 'MAR 2016',
    category: 'Completed', 
    backgroundImage: '/images/image.png',
    images: [
      '/images/18/18-1.jpg',
      '/images/18/18-2.jpg',
      '/images/18/18-3.jpg',
      '/images/18/18-4.jpg',
      '/images/18/18-5.jpg',
      '/images/18/18-6.jpg',
      '/images/18/18-7.jpg',
      '/images/18/18-8.jpg',
    ],
  },
  {
    id: 15,
    name: 'KEDAH PEJABAT 5 TINGKAT & 16 UNIT 4-TINGKAT',
    place: 'TERENGGANU',
    description: 'Cadangan Membina Dan Menyiapkan 1 Unit kedai pejabat 5 Tingkat di atas PT844 Dan 16 Unit 4-Tingkat Di atas PT828-PT843(Lot Asal 587)Pekan Kuala Berang,Daerah Hulu Terengganu.',
    client: 'Smart Returns Sdn Bhd',
    capital: 'RM 10,400,000.00',
    doc: 'MAR 2016',
    category: 'Completed',
    backgroundImage: '/images/image.png',
    images: [
      '/images/15/15-1.jpg',
      '/images/15/15-2.jpg',
      '/images/15/15-3.jpg',
      '/images/15/15-4.jpg',
      '/images/15/15-5.jpg',
      '/images/15/15-6.jpg',
      '/images/15/15-7.jpg',
    ],
  },
  {
    id: 16,
    name: '18 UNIT RUMAH BERKEMBAR 2 ½ TINGKAT',
    place: 'PERAK',
    description: 'Cadangan Membina 18 unit Rumah Berkembar 2 ½ tingkat Di Atas Lot Asal 15701,Mukim Sitiawan, Daerah Manjung ,Perak.',
    client: 'Smart Returns Sdn Bhd',
    capital: 'RM 4,554,000.00',
    doc: 'FEB 2016',
    category: 'Completed', 
    backgroundImage: '/images/image.png',
    images: [
      '/images/16/16-1.jpg',
      '/images/16/16-2.jpg',
      '/images/16/16-3.jpg',
      '/images/16/16-4.jpg',
      '/images/16/16-5.jpg',
      '/images/16/16-6.jpg',
      '/images/16/16-7.jpg',
      '/images/16/16-8.jpg',
      '/images/16/16-9.jpg',
    ],
  },
  {
    id: 13,
    name: '14 UNIT RUMAH TERES 1-TINGKAT & 14 UNIT Rumah BERKEMBAR 1-TINGKAT',
    place: 'PERAK',
    description: 'Cadangan Membina dan Menyiapkan Skim Perumahan Yang Mengandungi 14 unit Rumah Teres 1-Tingkat dan 14 Unit Rumah Berkembar 1-Tingkat, Di Atas Lot 1898, Mukim Lekir, Daerah Manjung, Perak',
    client: 'Misi Jutari Sdn Bhd',
    capital: 'RM 2,268,000.00',
    doc: 'MAR 2012',
    category: 'Completed',
    backgroundImage: '/images/image.png',
    images: [
      '/images/13/13-1.jpg',
      '/images/13/13-2.jpg',
      '/images/13/13-3.jpg',
      '/images/13/13-4.jpg',
      '/images/13/13-5.jpg',
      '/images/13/13-6.jpg',
    ],
  },
  {
    id: 14,
    name: '22 UNIT RUMAH BANGLO 1-TINGKAT',
    place: 'PERAK',
    description: 'Cadangan Membina dan Menyiapkan Skim Perumahan Yang Mengandungi 22 unit Rumah Banglo 1-Tingkat Di Atas Lot 5696, Mukim Lekir, Daerah Manjung, Perak.',
    client: 'Misi Jutari Sdn Bhd',
    capital: 'RM 2,574,000.00',
    doc: 'MAR 2012',
    category: 'Completed', 
    backgroundImage: '/images/image.png',
    images: [
      '/images/14/14-1.jpg',
      '/images/14/14-2.jpg',
      '/images/14/14-3.jpg',
      '/images/14/14-4.jpg',
    ],
  },
  {
    id: 12,
    name: 'DOUBLE STOREY TERRACE HOUSES',
    place: 'PERAK',
    description: 'Proposed Construction of 14 units Double Storey Terrace Houses and 13 Units Single Storey Terrace Houses On Lot 27. Changkat Kruing, Mukim Pengkalan Baharu, Daerah Manjung, Perak',
    client: 'Eclipse Property Sdn Bhd',
    capital: 'RM 2,256,000.00',
    doc: 'NOV 2011',
    category: 'Completed', 
    backgroundImage: '/images/image.png',
    images: [
      '/images/12/12-1.jpg',
      '/images/12/12-2.jpg',
    ],
  },
  {
    id: 10,
    name: 'DEWAN MAKAN DI WISMA PERWIRA KOR PERKHIDMATAN DIRAJA (PULMAT)',
    place: 'PERAK',
    description: 'Pembinaan Dewan Makan Di Wisma Perwira Kor Perkhidmatan Diraja (PULMAT) Taiping, Perak Darul Ridzuan ',
    client: 'Kesuma Anika Sdn Bhd',
    capital: 'RM 3,495,000.00',
    doc: 'JUN 2006',
    category: 'Completed', 
    backgroundImage: '/images/image.png',
    images: [
      '/images/10/10-1.jpg',
      '/images/10/10-2.jpg',
      '/images/10/10-3.jpg',
      '/images/10/10-4.jpg',
      '/images/10/10-5.jpg',
      '/images/10/10-6.jpg',
      '/images/10/10-7.jpg',
      '/images/10/10-8.jpg',
      '/images/10/10-9.jpg',
      '/images/10/10-10.jpg',
    ],
  },
  {
    id: 11,
    name: 'TOKONG KUAN TI',
    place: 'PERAK',
    description: 'Membekal, Membina dan Menyiapkan Tokong Kuan Ti-No. 302, Simpang Dua Mukim Sitiawan, Daerah Manjung Perak',
    client: 'Jawatankuasa Pembinaan Tokong Kuan Ti ',
    capital: 'RM 455,154.00',
    doc: 'JUL 2005',
    category: 'Completed',
    backgroundImage: '/images/image.png',
    images: [],
  },
  {
    id: 9,
    name: 'KILANG SETINGKAT DI ATAS PLOT 135 & 136',
    place: 'PERAK',
    description: 'Cadangan Membina Sebuah Kilang Setingkat Di Atas Plot 135 & 136, Kawasan Perindustrian Pelabuhan Lumut, Kampung Acheh, Mukim Lumut, Daerah Manjung, Perak Darul Ridzuan.',
    client: 'DBE Poultry Sdn Bhd',
    capital: 'RM 18,228,000.00',
    doc: 'JAN 2005',
    category: 'Completed', 
    backgroundImage: '/images/image.png',
    images: [
      '/images/9/9-1.jpg',
      '/images/9/9-2.jpg',
      '/images/9/9-3.jpg',
      '/images/9/9-4.jpg',
      '/images/9/9-5.jpg',
      '/images/9/9-6.jpg',
    ],
  },
  {
    id: 8,
    name: 'PROJEK PEMBANGUNAN PERTANIAN',
    place: 'PERAK',
    description: 'Cadangan Membina & Menyiapkan Taliair Konkrit Di Blok "A" Serta Kerja-kerja Berkaitan, Projek Pembangunan Pertanian Seberang Perak, Perak Darul Ridzuan.',
    client: 'Good Asia Development',
    capital: 'RM 5,722,050.00',
    doc: 'NOV 2003',
    category: 'Completed',
    backgroundImage: '/images/image.png',
    images: [],
  },
  {
    id: 5,
    name: 'PEMBANGUNAN SKIM PERUMAHAN: PAKEJ C',
    place: 'PERAK',
    description: 'Cadangan Pembangunan Skim Perumahan 1,000 unit Kos Rendah Di Atas Tanah Kerajaan Seluas 82.18 Ekar, Bandar Seri Iskandar, Mukim Bota, Daerah Perak Tengah Perak: Pakej C Yang Mengandungi 322 Unit Rumah Teres Kos, Infrastruktur & Kerja-kerja Luaran.',
    client: 'Hyclean Project Management Sdn Bhd',
    capital: 'RM 8,104,998.03',
    doc: 'OCT 2003',
    category: 'Completed', 
    backgroundImage: '/images/image.png',
    images: [
      '/images/5/5-1.jpg',
      '/images/5/5-2.jpg',
      '/images/5/5-3.jpg',
      '/images/5/5-4.jpg',
      '/images/5/5-5.jpg',
      '/images/5/5-6.jpg',
      '/images/5/5-7.jpg',
      '/images/5/5-8.jpg',
      '/images/5/5-9.jpg',
      '/images/5/5-10.jpg',
      '/images/5/5-11.jpg',
      '/images/5/5-12.jpg',
    ],
  },
  {
    id: 7,
    name: 'CHIKUS - TAPAH STATION',
    place: 'RAWANG - IPOH',
    description: 'The Execution & Completion Of Site Clearance Earthworks Culvert & Other Associated Works For The Electrified Double Tracking Project Between Rawang to Ipoh; Package 4 (Chikus - Tapah Station)',
    client: 'Good Asia Development',
    capital: 'RM 2,775,397.87',
    doc: 'SEP 2003',
    category: 'Completed', 
    backgroundImage: '/images/image.png',
    images: [],
  },
  {
    id: 6,
    name: 'BIDOR - SUNGKAR STATION',
    place: 'RAWANG - IPOH',
    description: 'The Execution & Completion Of Site Clearance Earthworks Culvert & Other Associated Works For The Electrified Double Tracking Project Between Rawang to Ipoh; Package 3B(Bidor - Sungkai Station)',
    client: 'Good Asia Development',
    capital: 'RM 7,983,861.60',
    doc: 'SEP 2003',
    category: 'Completed',
    backgroundImage: '/images/image.png',
    images: [],
  },
  {
    id: 4,
    name: 'BUMBUNG BERATAP CURAM UNTUK 39 BLOK RUMAH KELUARGA',
    place: 'LUMUT',
    description: 'Cadangan Merekabentuk,Membina & Menyiapkan Bumbung Beratap Curam Untuk 39 Blok Rumah Keluarga Kelas F & G di Pangkalan TLDM Lumut',
    client: 'Good Asia Development Sdn. Bhd',
    capital: 'RM 19,628,941.30',
    doc: 'JUN 2003',
    category: 'Completed',
    backgroundImage: '/images/image.png',
    images: [
      '/images/4/4-1.jpg',
      '/images/4/4-2.jpg',
      '/images/4/4-3.jpg',
      '/images/4/4-4.jpg',
      '/images/4/4-5.jpg',
      '/images/4/4-6.jpg',
      '/images/4/4-7.jpg',
    ],
  },
  {
    id: 3,
    name: 'PEMBANGUNAN SKIM PERUMAHAN: PAKEJ B ',
    place: 'PERAK',
    description: 'Cadangan Pembangunan Skim Perumahan 1,000 unit Kos Rendah Di Atas Tanah Kerajaan Seluas 82.18 Ekar, Bandar Seri Iskandar, Mukim Bota, Daerah Perak Tengah Perak: Pakej B Yang Mengandungi 317 Unit Rumah Teres Kos, Infrastruktur & Kerja-kerja Luaran',
    client: 'Good Asia Development Sdn. Bhd',
    capital: 'RM 8,495,000.00',
    doc: 'APR 2003',
    category: 'Completed', 
    backgroundImage: '/images/image.png',
    images: [
      '/images/3/3-1.jpg',
      '/images/3/3-2.jpg',
      '/images/3/3-3.jpg',
      '/images/3/3-4.jpg',
      '/images/3/3-5.jpg',
    ],
  },
  {
    id: 2,
    name: 'SEK. MEN. KEB. RINGKLET',
    place: 'PAHANG',
    description: 'Cadangan Merekabentuk, Membina, & Menyiapkan Sek. Men. Keb. Ringlet',
    client: 'Nilai Bina Sdn Bhd',
    capital: 'RM 24,806,000.00',
    doc: 'OCT 2002',
    category: 'Completed', 
    backgroundImage: '/images/image.png',
    images: [],
  },
  {
    id: 1,
    name: 'MAIN BRANCH AT MENARA MAYBANK',
    place: 'KUALA LUMPUR',
    description: 'Proposed Interior Design & Renovation Works to Ground & Mezzanine Floor, Banking Hall & Related Back Office Re-configuration, Main Branch at Menara Maybank',
    client: 'Active Point-Majutani Keda J.V. Sdn Bhd',
    capital: 'RM 4,774,740.95',
    doc: 'JAN 2002',
    category: 'Completed', 
    backgroundImage: '/images/image.png',
    images: [],
  },
  // {
  //   id: 27,
  //   name: 'PROJECT KOLAM UDANG',
  //   place: 'MANJUNG RAYA, LUMUT AND CHANGKAT MERANTI.',
  //   description: 'Cadagan Membina dan Menyiapkan Dan Menyiapkan Skim Perumahan Mampu Milik Fasa 1B Yang Mengandungi 1005 Unit Rumah Teres Dua Tingkat Di Atas Sebahangian GM105129 Lot 16684 Block B Dan C PT 42253 Mukim Sitiawan Daerah Manjung,Perak.',
  //   client: 'Rantau Urusan (M) Sdn Bhd',
  //   capital: 'RM 66,500,000.00',
  //   doc: 'Jul 2020',
  //   category: 'On-going',
  //   backgroundImage: '/images/image.png',
  //   images: [
  //     '/images/27/27-1.jpg',
  //     '/images/27/27-2.jpg',
  //     '/images/27/27-3.jpg',
  //     '/images/27/27-4.jpg',
  //     '/images/27/27-5.jpg',
  //     '/images/27/27-6.jpg',
  //     '/images/27/27-7.jpg',
  //     '/images/27/27-8.jpg',
  //     '/images/27/27-9.jpg',
  //     '/images/27/27-10.jpg',
  //     '/images/27/27-11.jpg',
  //     '/images/27/27-12.jpg',
  //   ],
  // },
];


export default projectData;
