import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaCaretDown } from 'react-icons/fa'; // Icons for the hamburger menu
import logo from '../images/GOOD ASIA CONSTRUCTION (Dark Horil).png';
import './Navbar.css';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  useEffect(() => {
    const closeMenuOnOutsideClick = (event) => {
      if (isMenuOpen && !event.target.closest('.navbar')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', closeMenuOnOutsideClick);

    return () => {
      document.removeEventListener('click', closeMenuOnOutsideClick);
    };
  }, [isMenuOpen]);

  const handleHamburgerClick = (event) => {
    // Prevent the click event from propagating to the document
    event.stopPropagation();
    toggleMenu();
  };

  return (
    <nav className="transparent-navbar">
      <div className="navbar-left">
        <Link to="/">
          <img src={logo} alt="Company Logo" className='logo'/>
        </Link>
      </div>
      <div className={`navbar-center ${isMenuOpen ? 'open' : ''}`}>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/career">Career</Link></li>
          <li><Link to="/news">News</Link></li>
          <div className="dropdown">
            <button className="dropbtn"><Link to="/projects">Projects</Link>
            <FaCaretDown />
            </button>
            <div className="dropdown-content">
              <li><Link to="/projects?category=On-going">On-going</Link></li>
              <li><Link to="/projects?category=Future">Future</Link></li>
              <li><Link to="/projects?category=Completed">Completed</Link></li>
            </div>
          </div>
        </ul>
      </div>

      <div className="navbar-right">
        <div className="hamburger" onClick={handleHamburgerClick}>
          {isMenuOpen ? <FaTimes className='close-sign'/> : <FaBars />}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
