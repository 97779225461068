import React, { useState } from 'react';
import image from '../images/career-2.jpg';
import './Career.css';

function Career() {
  const benefits = [
    {
      title: 'Professional Growth',
      description: 'Unlock your potential with opportunities for career advancement and skill development.',
    },
    {
      title: 'Diverse Projects',
      description: 'Work on a wide range of exciting projects, from residential to commercial, and leave your mark on skylines.',
    },
    {
      title: 'Team Collaboration',
      description: 'Join a tight-knit community of professionals who value teamwork and camaraderie.',
    },
    {
      title: 'Safety First',
      description: 'We prioritize your safety with comprehensive training and a commitment to maintaining a secure work environment.',
    },
    {
      title: 'Competitive Compensation',
      description: 'Receive competitive pay, benefits, and recognition for your hard work.',
    },
    {
      title: 'Sustainability',
      description: 'Be part of a company dedicated to sustainable construction practices, contributing to a greener future.',
    },
  ];

  const jobData = [
    {
      title: 'Purchasing & Facilities Executive (Construction)',
      location: 'Sitiawan / Seri Manjung',
      description: [
        'Coordinate with project and contract dept for material order’s requirements.',
        'Monitoring budgeted quantities.',
        'Sourcing supplier and get quotation.',
        'Plan, organize, purchase materials requested by site personnel.',
        'Coordinate and monitor materials purchased to ensure timely delivery.',
        'Negotiate pricing, terms, scheduling, stock available, etc.',
        'Present the comparison to director and get approval.',
        'Proceed to issue order and follow-up the delivery.',
        'Quality and specification check with site personnel.',
        'Tally DO with Purchase Order and Invoice.',
        'Summarise material purchase by projects.',
        'Coordinate material back charges with project & contract dept.',
        'To arranged schedule of lorry driver.',
      ],   
      type: 'Job Type: Full-time, Fixed shift',
      benefits: ' Benefits: Maternity leave, opportunities for promotion, professional development',
      pay: 'Supplemental Pay Types: Performance bonus, Yearly bonus',
      requirements: [
        'Ability to commute/relocate: Manjung: Reliably commute or planning to relocate before starting work (Required)',
        'Education: STM/STPM (Preferred)',
        'Experience: Buyer/Purchaser: 2 years (Preferred)'
      ]
    },
    {
      title: 'Quantity Surveyor',
      location: 'Sitiawan / Seri Manjung',
      description: [
        'Tender management including preparation of bills of quantities, contract conditions, and assembly of tender documents.',
        'Cost estimate, cost planning, and cost management. Checking developing designs against the project budget.',
        'Undertaking risk management and value management exercises.',
        'Estimating the cost of variations.',
        'Preparing valuation statements for interim certificates.',
        'Claims and dispute management.',
        'Benchmarking requirements against similar projects',
        'Helping determine the requirements and undertaking feasibility studies. ',
        'Advising on procurement strategy.',
        'Assessing tenders.',
        'Contract management and contractual advice.',
        'Valuation of construction work.',
        'Presenting detailed information on the cost of particular elements of work on a periodic basis to enable payment for those works carried out to-date.',
        'Organising the division of a project into its component work packages, then awarding these work packages to subcontractors.',
      ],   
      type: 'Job Type: Full-time, Permanent, Fixed shift',
      benefits: 'Benefits: Opportunities for promotion, professional development',
      pay: 'Supplemental Pay Types: Performance bonus, Yearly bonus',
      requirements: [
        'Able to attend Site Visit, Site Meeting, Site Valuation around Perak area.',
        'Ability to commute/relocate: Manjung: Reliably commute or planning to relocate before starting work (Required)',
        'Education: Diploma/Advanced Diploma (Preferred)',
        'Experience: Quantity Surveyor:  2 years (Preferred)'
      ]    
    },
    {
      title: 'Internship',
      location: 'Perak',
      description: [
        'Kickstart your career with our dynamic internship program,',
        'Gain hands-on experience and mentorship.',
      ],   
      type: '',
      benefits: '',
      pay: '',
      requirements: [
        'Enrollment in a relevant degree program.',
        'Strong communication skills.',
        'Basic computer and technology proficiency.',
        'Ability to work independently and as part of a team.',
        'Strong problem-solving and critical thinking skills.',
        'Willingness to learn and adapt to new tasks and challenges.',
      ]
    },
  ];

  const [expandedTabs, setExpandedTabs] = useState([]);

  const toggleTab = (index) => {
    if (expandedTabs.includes(index)) {
      setExpandedTabs(expandedTabs.filter((item) => item !== index));
    } else {
      setExpandedTabs([...expandedTabs, index]);
    }
  };

return (
  <div className='career-section'>
    <div className="career-section-header">
      <h2>Career</h2>
      <p>At Good Asia Constructions, we are more than just a builder. We are creators of opportunities. Join us in building a brighter future.</p>
    </div>

    <section className='benefits-section'>
      <h3>YOUR BENEFITS</h3>
      <div className='benefits-container'>
        {benefits.map((benefit, index) => (
          <div className='benefit-card' key={index}>
            <h4>{benefit.title}</h4>
            <p>{benefit.description}</p>
          </div>
        ))}
      </div>
            
      <div className="job-section">
        <div className="image-container">
          <img src={image} alt="Job Image" />
        </div>

        <div className="tabs-container">
          <h3 className='tabs-container-header'>OPEN POSITIONS</h3>
          {jobData.map((job, index) => (
            <div className={`tab ${expandedTabs.includes(index) ? 'expanded' : ''}`} key={index}>
              <div className="tab-header" onClick={() => toggleTab(index)}>
                <span className="job-title">{job.title}</span>
                <span className="job-location">{job.location}</span>
                <button className="expand-button">{expandedTabs.includes(index) ? '-' : '+'}</button>
              </div>
              <div className="job-description">
                <h4>Description: </h4>

                <ul>
                  {job.description.map((point, pointIndex) => (
                    <li key={pointIndex}>{point}</li>
                  ))}
                </ul>
              

                <p>{job.type}</p>
                <p>{job.benefits}</p>
                <p>{job.pay}</p>
                <h4>Requirements:</h4>

                <ul>
                  {job.requirements.map((point, pointIndex) => (
                  <li key={pointIndex}>{point}</li>
                  ))}
                </ul>
              </div>

            </div>
          ))}
        </div>
      </div>

    </section>
  </div>
  );
}
export default Career;
